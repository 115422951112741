<template>
    <div>
        <Quote title="Bu yerda siz dasturga kirishingiz mumkin"/>
        <GoBack next="Kirish"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-9 col-md-8">
                <form @submit.prevent="login" id="Logins ">
                    <div id="log">
                        <input
                            type="email"
                            class="form-control"
                            id="exampleInputEmail1"
                            aria-describedby="emailHelp"
                            placeholder="Email"
                            v-model="form.email"
                        />
                    </div>
                    <div id="password">
                        <div class="input-container">
                            <input
                                :type="showPassword ? 'text' : 'password'"
                                class="form-control"
                                id="exampleInputPassword1"
                                placeholder="Parol"
                                v-model="form.password"
                            />
                            <div class="eye-icon" @click="toggleShowPassword">
                                <i :class="['fa', showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end pt-1">
                            <router-link
                                to="/forgot-password"
                                class="hover-decoration d-flex justify-content-end"
                                style="font-size: small; color: black"
                            >
                                Parolni unutdingizmi?
                            </router-link>
                        </div>
                    </div>
                    <div class="d-block d-md-flex justify-content-between align-items-center">
                        <div
                            class="d-none d-md-flex mr-2"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-3 mt-md-0">
                                Agar hali ro'yxatdan o'tmagan bo'lsangiz,
                                <router-link to="/registration" class="hover-decoration">ro'yxatdan o'ting.
                                </router-link>
                            </p>
                        </div>
                        <div class="row d-none d-md-flex justify-content-left col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                :disabled="disableButton"
                                type="submit"
                                class="btn btn-primary"
                            >
                                Kirish
                            </button>
                        </div>
                        <!--ekran md da button col-12 bo`ladi-->
                        <div class="row d-flex d-md-none justify-content-left col-12 col-md-5 col-lg-4 col-xl-3">
                            <button
                                :disabled="disableButton"
                                type="submit"
                                class="btn btn-primary"
                            >
                                Kirish
                            </button>
                        </div>
                        <div
                            class="d-flex d-md-none"
                            style="font-size: 15px"
                            @click="scrollToTop()"
                        >
                            <p class="mt-3 mt-md-0">
                                Agar hali ro'yxatdan o'tmagan bo'lsangiz,
                                <router-link to="/registration" class="hover-decoration">ro'yxatdan o'ting.
                                </router-link>
                            </p>
                        </div>
                    </div>
                </form>
                <div>
                    <b-modal ref="modal-error" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Bunday foydalanuvchi mavjud emas!</h3>
                        </div>
                        <b-button
                            class="mt-3"
                            variant="btn btn-primary"
                            block @click="hideErrorModal"
                        >
                            Qayta urinish
                        </b-button>
                    </b-modal>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import GoBack from "@/components/GoBack";
import Quote from "@/components/Quote";
import {mapActions} from "vuex"

export default {
    name: "Login",
    components: {GoBack, Quote},
    methods: {
        ...mapActions(['fetchToken']),
        login() {
            this.fetchToken(this.form)
                .then(() => {
                    this.$router.push('/cabinet')
                })
                .catch(() => {
                    console.log('loginda token olishda xato')
                    this.$refs['modal-error'].show()
                })
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        hideErrorModal() {
            this.$refs['modal-error'].hide()
        },
        toggleShowPassword() {
            this.showPassword = !this.showPassword;
        },
    },
    computed: {
        disableButton() {
            return this.form.email.length === 0 || this.form.password.length === 0
        },
    },
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            showPassword: false
        }
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap');

* {
    padding: 0;
    margin: 0;
}

#Logins {
    /* Auto Layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 0;
    width: auto;
    height: 174px;
    left: 259.5px;
    top: 111px;
}

#log {
    left: 0;
    top: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #A6A6A6;
    background: #FFFFFF;
}

#exampleInputEmail1, #exampleInputPassword1 {
    border: 1px solid #80007F;
    box-sizing: border-box;
    border-radius: 10px;
    padding-left: 10px !important;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

#password {
    left: 0;
    top: 68px;
    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 30px 0;
    font-family: 'Comfortaa', cursive;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 20px;
    color: #A6A6A6;
    background: #FFFFFF;
}

.justify-content-left .btn-primary {
    text-align: center;
    padding: 5px 60px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: 'Comfortaa', cursive;
    font-size: 16px;
}

.btn-primary {
    background-color: #80007F !important;
    border-color: #80007F;
    outline: none !important;
    color: #fff;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: none !important;
}

.btn-primary:hover {
    background-color: #990098 !important;
    border-color: #990098 !important;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.eye-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    cursor: pointer;
}

.input-container {
    position: relative;
}

</style>
